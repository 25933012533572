
$(document).ready(function() {

    $('div.alert').not('.alert-important').delay(5000).slideUp(400);

    // selectNavInit();
    tableClickRow();

    btnFileEvents();
    confirmDeleted();
});


function selectNavInit()
{
    $nav = $("#nav #_"+nav_item_select)
    $nav.addClass("active");

    if($nav.hasClass('dropdown')){
        $nav.find('ul.dropdown-menu li').clone().appendTo( "#subnav #container" );
        $subnav = $("#subnav #_"+nav_subitem_select);

        if($subnav.length > 0){
            $subnav_position = $subnav.position();
            $subnav.addClass("active");

            $("#subnav #arrow").css('left', $subnav_position.left + ($subnav.width()/2) - ($("#subnav #arrow").width() /2) -1);
        }
    }
}

function tableClickRow()
{

    $("table tbody.with-url tr td").not(".actions").not(".normal").addClass('with-url');
    //$("table tbody.with-url tr td").not(".actions").not(".normal").on('click', function () {
    $("table tbody.with-url tr td.with-url").on('click', function () {

        var url = $(this).parent('tr').attr('data-url');
        $(this).parent('tr').addClass('onClick');
        console.log(url);
        window.location.href = url;
    });
}

function btnFileEvents()
{
    $(".btn-file input").on('change', function(){

        var image = $(this).val();
        image = image.split('\\');
        image = image[image.length-1];

        console.log('Change file: ' + image);
        $("span.label-file").html(image);
    })
}


function confirmDeleted()
{
    $("form.form-delete").on("submit", function(){

        var agree = confirm('Você realmente deseja excluir?');

        if(agree){
            var loader = '<img src="' + baseurl + 'assets/images/shared/loader6.gif' + '">';
            $(this).find('button').html(loader);
            $(this).parents('tr').hide(700);
            return true;
        }else{
            return false;
        }
    });
}




